import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

// App components
import CustomLink from '../components/CustomLink'
import Layout from '../components/layout'
import MainHelmet from '../components/MainHelmet'

// App utils
import { rhythm, scale } from '../utils/typography'
import { themeStyles } from '../utils/theme'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location}>
        <MainHelmet
          description={post.excerpt}
          title={`${post.frontmatter.title} | ${siteTitle}`}
        />
        <div css={themeStyles.textPadding}>
          <h1>{post.frontmatter.title}</h1>
          <p
            css={{
              ...scale(-1 / 5),
              display: 'block',
              marginBottom: rhythm(1),
              marginTop: rhythm(-1),
            }}
          >
            {post.frontmatter.date}
          </p>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
          <hr
            css={{
              marginBottom: rhythm(1),
            }}
          />
          <ul
            css={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              listStyle: 'none',
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <CustomLink to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </CustomLink>
              )}
            </li>
            <li>
              {next && (
                <CustomLink to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </CustomLink>
              )}
            </li>
          </ul>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
